<template>
  <div v-if="status">
    <div class="mb--sm--2" style="font-size: 18px">
      <strong>Trạng thái đơn hàng:</strong>
    </div>
    <div class="order-status-card mb--sm--4">
      <div class="order-status-card__item" :class="[['Z1', 'Z3'].includes(status) ? 'order-status-card__item--active' : '']">Chờ xác nhận</div>
      <div class="order-status-card__item" :class="[['Z2'].includes(status) ? 'order-status-card__item--active' : '']">
        Xác nhận
      </div>
      <div class="order-status-card__item" :class="[['Z4'].includes(status) ? 'order-status-card__item--active' : '']">Đang giao</div>
      <div class="order-status-card__item" :class="[['Z5'].includes(status) ? 'order-status-card__item--active' : '']">Đã giao</div>
      <div class="order-status-card__item" :class="[['Z6', 'Z7'].includes(status) ? 'order-status-card__item--active' : '']">Đã huỷ</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    status: String
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.order-status-card {
  display: flex;
  flex-direction: column;
  &__item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 24px;
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border: 1px solid #003b35;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      background-color: #003b35;
      height: 100%;
      position: absolute;
      left: 5px;
      top: 100%;
    }
    &--active {
      font-weight: 600;
      color: #b3202e;
      &::before {
        border-color: #b3202e;
        background-color: #b3202e;
      }
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
}
</style>
