<template>
  <div class="page">
    <Header />
    <div class="container" v-if="order">
      <div
        class="h5 font-weight--600 text--back text--sm--center mb--sm--5 mt--sm--15"
      >
        Lịch sử đơn hàng
      </div>
      <div>
        <p class="h5" v-if="order.customerName"><strong style="text-decoration: underline">{{order.customerName}}</strong></p>
        <p class="h5" v-if="order.orderID"><strong class="mr--sm--2" >Đơn hàng:</strong><span style="text-decoration: underline">{{order.orderID}}</span><span v-if="order.orderNumberSAP && (order.status !== 'Z1' && order.status !== 'Z3')"> - <span style="text-decoration: underline">{{order.orderNumberSAP}}</span></span></p>
        <p class="font-weight--400" style="font-size: 18px" v-if="order.orderDate"><strong class="mr--sm--2">Ngày đặt hàng:</strong>{{order.orderDate === '00000000' ? '00/00/0000' : $moment(order.orderDate).format('DD/MM/YYYY')}}</p>
        <p class="font-weight--400" style="font-size: 18px" v-if="order.amountAfterTax"><strong class="mr--sm--2">Thành tiền:</strong>{{parseInt(order.amountAfterTax).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</p>
        <OrderStatusCard :status="order.status"/>
        <div class="mb--sm--6" v-if="order.deliveryAddress">
          <div class="address-card">
            <div class="h5 font-weight--600">Địa chỉ giao hàng:</div>
           <div>{{order.deliveryAddress}}</div>
        </div>
        </div>
        <p class="h5 font-weight--400" v-if="order.deliveryDate"><strong class="mr--sm--5">Ngày giao hàng:</strong>{{order.deliveryDate === '00000000' ? '00/00/0000' : $moment(order.deliveryDate).format('DD/MM/YYYY')}}</p>
        <hr />
        <div class="h5"><strong>Sản phẩm đã đặt:</strong></div>
        <div class="row mb--sm--6" v-if="order.orderLines && order.orderLines.results && order.orderLines.results.length > 0">
          <div class="col col--sm--12 col--md--6" v-for="cartItem in order.orderLines.results" :key="cartItem.productCode">
            <CheckoutProductItem :cartItem="cartItem" />
          </div>
        </div>
        <div class="cart__info h5 font-weight--sm--500 mb--sm--5">
          <div class="cart__info__item" v-if="order.amountBeforetax">
            <div class="cart__info__left">Tổng giá trị thành tiền:</div>
            <div class="cart__info__right" style="color: #B93D00;">{{parseInt(order.amountBeforetax).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
          </div>
          <div class="cart__info__item" v-if="order.taxAmount">
            <div class="cart__info__left">VAT:</div>
            <div class="cart__info__right" style="color: #B93D00;">{{parseInt(order.taxAmount).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
          </div>
          <div class="cart__info__item" v-if="order.taxAmount">
            <div class="cart__info__left" style="color: #008000;">Chiết khấu:</div>
            <div class="cart__info__right" style="color: #008000;">{{parseInt(order.discountAmount).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
          </div>
          <div class="cart__info__item" style="color: #B3202E;" v-if="order.amountAfterTax">
            <div class="cart__info__left">Tổng thanh toán:</div>
            <div class="cart__info__right">{{parseInt(order.amountAfterTax).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
          </div>
        </div>
        <div class="ctkm" v-if="order.saleDeal">
          <div class="ctkm__label">CTKM:</div>
          <div class="ctkm__list">
           <div class="ctkm__item" v-for="(saleDealItem, index) in order.saleDeal.split(',')" :key="index">
             <div class="ctkm__item__name" v-if="saleDealItem">{{saleDealItem}}</div>
           </div>
          </div>
        </div>
        <div class="ctkm" v-if="order.codeDT">
          <div class="ctkm__label">Mã dự thưởng:</div>
          <div class="ctkm__list">
           <div class="ctkm__item" v-for="(codeDTItem, index) in order.codeDT.split(',')" :key="index">
             <div class="ctkm__item__name" v-if="codeDTItem">{{codeDTItem}}</div>
           </div>
          </div>
        </div>
        <div class="ctkm" v-if="order.saleDealCus">
          <div class="ctkm__label">Boston App:</div>
          <div class="ctkm__list">
           <div class="ctkm__item" v-for="(saleDealCusItem, index) in order.saleDealCus.split(',')" :key="index">
             <div class="ctkm__item__name" v-if="saleDealCusItem">{{saleDealCusItem}}</div>
           </div>
          </div>
        </div>
        <div class="ctkm" v-if="order.promotionX1Code">
          <div class="ctkm__label">Boston App Extra:</div>
          <div class="ctkm__list">
           <div class="ctkm__item" v-for="(promotionX1CodeItem, index) in order.promotionX1Code.split(',')" :key="index">
             <div class="ctkm__item__name" v-if="promotionX1CodeItem">{{promotionX1CodeItem}}</div>
           </div>
          </div>
        </div>
        <div class="h5 mb--sm--5" style="background-color:#CBE3E0; border-radius: 15px; padding: 8px 16px; display: flex" v-if="order.note">Ghi chú: <span style="flex: 1; margin-left: 8px">{{order.note}}</span></div>
      </div>
      <div class="text--sm--right mt--sm--9 mt--md--20" v-if="order.orderLines && order.orderLines.results && order.orderLines.results.length > 0">
        <HdButton v-on:click="reBuyClickHandle(order.orderLines.results)">
          Mua lại sản phẩm
          <template v-slot:icon><RebuyIcon/></template>
        </HdButton>
      </div>
    </div>
    <div class="container d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column" v-if="!order">
        <div class="h5 text--black font-weight--600 mb--sm--20 text--sm--center">Mã đơn hàng không tồn tại .</div>
        <ConfirmCheckout class="mb--sm--10"/>
        <div class="h4 font--roboto font-weight--400 mb--sm--4 text--sm--center">It looks like nothing was found at this location.</div>
    </div>
    <Footer />
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import OrderStatusCard from '@/components/OrderStatusCard.vue'
import RebuyIcon from '@/assets/re-buy.svg'
import CheckoutProductItem from '@/components/CheckoutProductItem.vue'
import HdButton from '@/components/HdButton'
import ConfirmCheckout from '@/assets/confirm-checkout.svg'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    OrderStatusCard,
    RebuyIcon,
    CheckoutProductItem,
    HdButton,
    ConfirmCheckout
  },
  data () {
    return {}
  },
  methods: {
    reBuyClickHandle: function (items) {
      this.$confirm('Bạn có muốn mua lại sản phẩm Không?', 'Thông báo', {
        confirmButtonText: 'Mua lại',
        cancelButtonText: 'Không'
      })
        .then(_ => {
          this.$store.commit('cart/setCartItems', items)
          this.$notify({
            duration: 1000,
            title: 'Thành công!',
            message: 'Thêm vào giỏ hàng thành công!',
            type: 'success'
          })
          this.$router.push('/cart').catch(() => {})
        })
        .catch(_ => {})
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: true,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)

    this.$store.dispatch('customer/getOrder', {
      orderID: this.$route.params.orderID
    })
  },
  computed: {
    order () {
      return this.$store.state.customer.order
    }
  }
}
</script>

<style lang="scss" scoped>
  .address-card {
    background-color:#E5F6FF;
    border-radius: 15px;
    padding: 16px;
  }
  .cart__info {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    &__left {
    }
    &__right {
      flex: 1;
      text-align: right;
    }
  }
  .ctkm {
        display: flex;
        align-items: center;
        &__label {
            font-size: 20px;
            font-weight: 500;
            line-height: 76px;
            min-width: 110px;
        }
        &__icon{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 4px #5EB5AF;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                opacity: 0.75;
            }
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: solid 4px #19958C;
            }
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            align-items: center;
            margin-top: 20px;
        }
        &__item {
            height: 36px;
            line-height: 36px;
            background: rgba(243, 111, 32, 0.15);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-bottom: 20px;
            &__name {
                font-size: 20px;
                font-weight: 600;
                color: #B93D00;
                text-transform: uppercase;
                padding: 0 8px;
                flex: 1;
            }
            &__close {
                border-left: solid 1px #fff;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 8px;
                cursor: pointer;
                transition: all 0.3s;
                &:hover {
                    opacity: 0.75;
                }
                svg {
                    transform: rotate( 45deg );
                }
            }
        }
    }
</style>
